import {graphql} from 'gatsby'
import ContactSection from './../components/contact-section'
import PrivacyPolicySection from './../components/privacy-policy-section'
import HoldingPageHeroImage from './../components/holding-page-hero-image'
import Layout from './../components/layout'
import React, {useState} from 'react'
import {
  HOLDING_PAGE_CSS_MODIFIER,
  PRIVACY_POLICY_SECTION_CSS_MODIFIER,
  PAGE_ROOT_ELEMENT_ID,
} from './../constants/global-constants'

export default props => {
  const {data} = props
  const prismicData = {
    showTheHoldingPage: data.prismicHoldingPage.data.show_the_holding_page,
  }

  return (
    <Layout
      documentTitlePrefix="PRIVACY POLICY"
      isIndexPage={false}
      pageName="privacy-policy"
      showScrollToTopBtn={false}
      showTheHoldingPage={prismicData.showTheHoldingPage}
    >
      {prismicData.showTheHoldingPage ? (
        <>
          <HoldingPageHeroImage />
          <ContactSection cssModifier={HOLDING_PAGE_CSS_MODIFIER} />
        </>
      ) : (
        <>
          <PrivacyPolicySection cssModifier={PRIVACY_POLICY_SECTION_CSS_MODIFIER} />
        </>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    prismicHoldingPage {
      data {
        show_the_holding_page
      }
    }
  }
`

import {graphql, useStaticQuery} from 'gatsby'
import Container from './container'
import Fade from 'react-reveal/Fade'
import PageHeading from './page-heading'
import React from 'react'
import styled from 'styled-components'

export default function PrivacyPolicySection({cssModifier}) {
  const data = useStaticQuery(graphql`
    {
      prismicPrivacyPolicyPage {
        data {
          page_heading {
            text
          }
          privacy_policy_intro_text {
            html
          }
          privacy_policy_sections {
            section_heading {
              text
            }
            section_body_text {
              html
            }
          }
        }
      }
    }
  `)

  const prismicData = {
    introText: data.prismicPrivacyPolicyPage.data.privacy_policy_intro_text.html,
    pageHeading: data.prismicPrivacyPolicyPage.data.page_heading.text,
    privacyPolicySections: data.prismicPrivacyPolicyPage.data.privacy_policy_sections,
  }

  return (
    <StyledComponent className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}>
      <PageHeading heading={prismicData.pageHeading} />

      <Container type="wide">
        <StyledPrivacyPolicy>
          <Fade>
            <div className="privacy-policy-intro-text" dangerouslySetInnerHTML={{__html: prismicData.introText}} />
          </Fade>

          {prismicData.privacyPolicySections &&
            prismicData.privacyPolicySections.map((item, index) => (
              <section className="privacy-policy-section" key={`privacy-policy-section-${index}`}>
                <Fade>
                  <h3 className="privacy-policy-section-heading">{item.section_heading.text}</h3>
                </Fade>
                <Fade>
                  <div
                    className="privacy-policy-section-body-text"
                    dangerouslySetInnerHTML={{__html: item.section_body_text.html}}
                  />
                </Fade>
              </section>
            ))}
        </StyledPrivacyPolicy>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  margin-top: 2rem;
`

const StyledPrivacyPolicy = styled.div`
  margin: 0 auto;
  max-width: 90rem;

  .privacy-policy-intro-text {
    margin-bottom: 4rem;
  }

  .privacy-policy-section {
    margin-bottom: 6rem;
  }

  .privacy-policy-section-heading {
    margin-bottom: 4rem;
  }

  .privacy-policy-section-body-text {
    padding-left: 2.5rem;
  }
`
